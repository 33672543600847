import React from "react";
import "./index.css";

export const RegistrationClosed = () => {
  return (
    <>
      <div className="register-closed-main">
        <div>Registrations are closed. </div>
        <div>Results will be Declared on 5th September</div>
    </div>
    </>
  );
};
