import React from 'react'
import Navbar from '../Navbar'
import Footer from '../footer'
import { FitScreen } from '@mui/icons-material';
import './ResultPhase2.css'

const ResultPhase2 = () => {

  const pools = [
    {
      name: "Pool Little Champs",
      results: [
        // { siNo: 1, name: "Yash", state: "Haryana", percentage: 90.1, Testpin: "subodhg44@gmail.com", school: "Rps kosli" },
        { siNo: 1, name: "Yasharth Singh", state: "Uttar Pradesh", percentage: 80.1, Testpin: "R939973", school: "City Montessori School" },
        { siNo: 2, name: "Shaurya", state: "Haryana", percentage: 73.5, Testpin: "R933945", school: "RPS kosli" },
        { siNo: 3, name: "Charu Yadav", state: "Rajasthan", percentage: 71.6, Testpin: "R078029", school: "Yaduvanshi Shiksha Niketan, Narnaul" }
      ]
    },
    {
      name: "Pool Super Nova",
      results: [
        { siNo: 1, name: "Yashvardhan", state: "Haryana", percentage: 86.3, Testpin: "R970708", school: "RPS Public School" },
        { siNo: 2, name: "Daksh", state: "Haryana", percentage: 81.9, Testpin: "R405106", school: "RPS Kosli" },
        { siNo: 3, name: "Komal sangwan", state: "Haryana", percentage: 81.3, Testpin: "R136502", school: "YSN Satnali" }
      ]
    },
    {
      name: "The Titans",
      results: [
        { siNo: 1, name: "Durga nandini", state: "Haryana", percentage: 78.4, Testpin: "R887111", school: "HD. Sr. Sec. School" },
        { siNo: 2, name: "Raghav Gupta", state: "Haryana", percentage: 61.6, Testpin: "R732801", school: "Rps Rohtak" },
        { siNo: 3, name: "Pulkit Sharma", state: "Haryana", percentage: 61.6, Testpin: "R437997", school: "S.N.D. Public School" }
      ]
    },
    {
      name: "Elite Explorers",
      results: [
        { siNo: 1, name: "Ayush Chandra", state: "Uttar Pradesh", percentage: 64.1, Testpin: "R068035", school: "St.Joseph Sr.Sec School" },
        { siNo: 2, name: "Atharva Agarwal", state: "Uttar Pradesh", percentage: 61.9, Testpin: "R605531", school: "Allenhouse Public School" },
        { siNo: 3, name: "Piyush Yadav", state: "Haryana", percentage: 61.6, Testpin: "R357609", school: "Yaduvanshi Shiksha Niketan" }
      ]
    }
  ];

  return (
    <>
      <Navbar/>

      {/* Background section */}
      <div className="bg-cover bg-center bg-no-repeat min-h-screen relative" style={{ backgroundImage: 'url(./bg-head.jpg)' }}>
        <div className="absolute inset-0 bg-black opacity-50"></div>
      </div>

      {/* Content section with padding to prevent hiding behind navbar */}
      <div className="relative pt-20"> {/* Add top padding to push content below the navbar */}
        <h1 className="text-center mt-10 text-white">Results of Phase 2</h1>
        <div className="flex flex-rows  justify-between gap-8 px-4">
          {pools.map((pool, index) => (
            <div key={index} className="flex bg-white min-w-[250px] rounded-lg border border-gray-200 shadow-md p-4">
              <h2 className="font-semibold text-gray-800 text-center mb-4">
                {pool.name}
              </h2>
              <div className='text-6xl phase2' style={{fontSize:'30px'}}>Top 3 Students</div>
              <ul className="space-y-4 lg:flex-col lg:gap-0 phase2" style={{display:'flex', gap:"40px"}}>
                
                {pool.results.map((result, i) => (
                    
                  <li key={i} className="p-4 bg-gray-50 rounded-md shadow w-fit gap-4 result-container"
                  style={{width:'fit-content'}}>
                    <p className="text-lg font-medium text-gray-700">
                      {result.siNo}. {result.name} ({result.state})
                    </p>
                    <p className="text-sm text-gray-600">Percentage: {result.percentage}%</p>
                    <p className="text-sm text-gray-600">Testpin: {result.Testpin}</p>
                    <p className="text-sm text-gray-600">School: {result.school}</p>
                  </li>
                  
                ))}
              </ul>
            </div>
          ))}
        </div>
      </div>

      <Footer/>
    </>
  )
}

export default ResultPhase2
